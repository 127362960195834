<template>
  <div class="mini_game_bet">
    <div class="mini_game">
      <div class="m1 dmr">
        <div class="mini_fram">
          <leisure-game-ifram-view>
            <iframe id="pam-gamefram" name="stream_frm" scrolling="no" frameborder="0"
                    src="http://pamgame.com/slot">
            </iframe>
          </leisure-game-ifram-view>
        </div>
      </div>
      <div class="m2">
        <div class="game_tab">
          <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
          <leisure-timer-comp
              ref="ltimer"
              :leisure-game-info="leisureGameInfo"
              @timerStop="timerStop" @refreshTimer="refreshTimer">
            <span slot="title" class="text-orange"><i class="fa fa-star"></i>티몬품바</span>
          </leisure-timer-comp>

          <!--선택부분-->
          <div class="mini_bet_box">
            <div class="mg">
              <span class="t">Timon/Pumba</span>
              <div class="mg_btn b2 bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_PAMGAME_SLOT_NORMAL && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_PAMGAME_SLOT_NORMAL,leisureGameInfo.leisureGame.id,1, normalConfig.odds1, normalConfig, 'Timon')">
                <div class="r rblue">
                  <span class="n">Timon</span>
                  <span class="b">{{ normalConfig.odds1 }}</span>
                </div>
              </div>

              <div class="mg_btn b2 bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_PAMGAME_SLOT_NORMAL && selectedInfo.selectedWay ===2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_PAMGAME_SLOT_NORMAL,leisureGameInfo.leisureGame.id,2,  normalConfig.odds2, normalConfig, 'Pumba')">
                <div class="r rred">
                  <span class="n">Pumba</span>
                  <span class="b">{{ normalConfig.odds2 }}</span>
                </div>
              </div>
            </div>
          </div>

          <!--베팅카트-->
          <leisure-bet-cart-comp
              ref="betCart" :kind="kind"
              :selected-info="selectedInfo">
            티몬품바
          </leisure-bet-cart-comp>


        </div>
      </div>
      <div class="m3">

        <!--최근 배팅내역-->
        <leisure-lately-bet-list-comp
            ref="latelyBetList"
            :kind="kind"></leisure-lately-bet-list-comp>
      </div>


    </div>
  </div>
</template>

<script>
import LeisureTimerComp from "@/views/afront/leisure/LeisureTimerComp";
import LeisureBetCartComp from "@/views/afront/leisure/LeisureBetCartComp";
import LeisureBetMasker from "@/views/afront/leisure/LeisureBetMasker";
import LeisureLatelyBetListComp from "@/views/afront/leisure/LeisureLatelyBetListComp";
import LeisureGameIframView from "@/views/afront/leisure/LeisureGameIframView";

import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "@/store/mutation-types";
import leisureConst from "@/common/leisureConst";
import {leisureGameMixin} from "@/common/mixin";
import {getLeisureGameAttributeConfig} from "@/network/leisureRequest";

export default {

  name: "PamGameSlot",
  mixins: [leisureGameMixin],
  components: {
    LeisureGameIframView,
    LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp,
  },
  data() {
    return {
      kind: leisureConst.LEISURE_KIND_PAMGAME_SLOT,
    }
  },

  methods: {
    initLeisureGameArttributeConfig() {
      getLeisureGameAttributeConfig().then(res => {
        if (res.data.success) {
          this.attrConfig = res.data.data
          //배당설정
          this.normalConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_PAMGAME_SLOT_NORMAL)
          })
        }
      })
    },

  },
  created() {
    this.initKindConfig(this.kind)
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP, leisureConst.LEISURE_COMP_PAMGAME);
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID, this.kind);
  },
  beforeDestroy() {
  }
}
</script>

<style scoped>

</style>